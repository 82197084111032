import moment from "moment/moment"

export default () => {
    let today = new Date()
    //today = new Date(2023, 6, 4)
    //if(today.getMonth() <= 8 || (today.getMonth() <= 9 && today.getDate() <= 1)){
    //    // console.log('ss', Date(2023,8,1))
    //     return new Date(2023,9,2)
    // }
    //|| (today.getMonth() <= 1 && today.getDate() <= 7)
    console.log( today.getMonth() , today.getDate())
    
    if( moment().isBefore(moment(new Date(2024,5,4))) ){
        return new Date(2024,5,4)
    }
    return today
}