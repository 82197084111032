import './App.css';

import React, {Suspense}  from 'react';
import { Routes, Route } from "react-router-dom";
import AdminMiddleware from './admin/AdminMiddleware';
import AdminUsers from './admin/AdminUsers';
import AdminOrders from './admin/AdminOrders';
import Header from './components/Header';
import AdminHome from './admin/AdminHome';
import AdminCourse from './admin/AdminCourse';
import './assets/tableStyle.css'
import AdminAdmins from './admin/AdminAdmins';
import AdminVideos from './admin/AdminVideos';
import AdminNotification from './admin/AdminNotification';
import AdminVideoCollection from './admin/AdminVideoCollection';

function AdminApp() {

  return (
    <div className="App">
        <Header />
        <Routes>
            <Route path='/home' exact element={<AdminHome />} />
            <Route path='/users' exact element={<AdminUsers />} />
            <Route path='/admins' exact element={<AdminAdmins />} />
            <Route path='/orders' exact element={<AdminOrders />} />
            <Route path='/videos' element={<Suspense fallback={<div>Loading...</div>}><AdminVideos /></Suspense>}></Route>
            <Route path='/videos/:collectionId' element={<Suspense fallback={<div>Loading...</div>}><AdminVideoCollection /></Suspense>}></Route>
            <Route path='/courses' element={<Suspense fallback={<div>Loading...</div>}><AdminCourse  type="live-class"/></Suspense>}></Route>
            <Route path='/notification' element={<Suspense fallback={<div>Loading...</div>}><AdminNotification/></Suspense>}></Route>
        </Routes>
    </div>
  );
}
export default AdminMiddleware(AdminApp);
