import React from "react";
import './courseStyle.css'

export default ({mobile = false}) => {
    const lectures = [
        `Drawing Support & Resistance Levels`,
        `Indicators & the reason for not using them`,
        `Nature of Market & Price Travel`,
        `Gap up Gap Down Strategy `,
        `Fibonacci Setup `,
        `M & W | 3 Line Setup`,
        `Cup & Handle`,
        `Head & Shoulder`,
        `All Important Chart Patterns`,
        `Strike Price Selection`,
        `Putting the Stoploss`,
        `Trailing Stoploss`,
        `Trading in Trending Market`,
        `Trading in Sideways Market`,
        `Risk Management `,
        `Psychology & Discipline of a Trader`,
    ]
    
    return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6" style={{color: 'white', minHeight: 200}}>
        
        {mobile && <link rel="stylesheet" type="text/css" href={'mobileapp.css'} />}
        {/* <h2 className="text-center text-4xl my-6 py-6">Syllabus of this Course</h2> */}
        {/*<div className="grid grid-cols-1 md:grid-cols-2 mt-4">*/}
        <div className="mt-4">
            
            {/* <ul className="max-w-lg mx-auto text-start" style={{ minWidth: 250 }}>
                <li>✓ How to Draw the Levels I draw? </li>
                <li>✓ Why I don't use any Indicators</li>
                <li>✓ Gap up Gap down strategy </li>
                <li>✓ Fibonacci Set-up </li>
                <li>✓ Legendary M & W pattern </li>
                <li>✓ Cup & Handle </li>
                <li>✓ Head & Shoulder </li>
                <li>✓ All important chart patterns </li>
            </ul>

            <ul className="max-w-lg mx-auto text-start" style={{ minWidth: 250 }}>
                <li>✓ Which Strike Price to buy? </li>
                <li>✓ How to put the Stoploss? </li>
                <li>✓ When to enter? </li>
                <li>✓ When to exit? </li>
                <li>✓ When to avoid? </li>
                <li>✓ Nature of Market </li>
                <li>✓ Trading in trending market </li>
                <li>✓ Trading in Sideways market</li>
                <li>✓ DISCIPLINE OF A TRADER </li>
            </ul> */}

            {/*<ul className="max-w-lg mx-auto text-start mfw" style={{ minWidth: 250 }}>
                {lectures.filter((v, vi) => vi < 8).map((v, vi) => {
                    return (<li>✓ {v}</li>)
                })}
            </ul>

            <ul className="max-w-lg mx-auto text-start mfw" style={{ minWidth: 250 }}>
            {lectures.filter((v, vi) => vi >= 8).map((v, vi) => {
                    return (<li>✓ {v}</li>)
                })}
            </ul>*/}

                <h3 className="mt-4 pt-4">What is Price Action?</h3>
                <ul>
                    <li>• Market Nature & Price Movement</li>
                    <li>• Indicators & my views for not using them</li>
                </ul>

                <h3 className="mt-4 pt-4">Support & Resistance Levels</h3>
                <ul>
                    <li>• Flat Market Levels</li>
                    <li>• Gap up/Gap Down Levels</li>
                    <li>• Strength of Levels</li>
                </ul>


                <h3 className="mt-4 pt-4">Important Chart Patterns for Beginners </h3>
                <ul>
                    <li>• Fibonacci Setup</li>
                    <li>• M/W Setup | 3 Line Setup</li>
                    <li>• Cup & Handle Setup</li>
                    <li>• Head & Shoulder Setup</li>
                    <li>• Inside Candle Strategy</li>
                </ul>


                <h3 className="mt-4 pt-4">Suitable for Beginners</h3>
                <ul>
                    <li>• Strike Price Selection</li>
                    <li>• Putting the Stoploss</li>
                    <li>• Reducing the Stoploss using Risk Management</li>
                    <li>• Increasing the target using Trailing Stoploss</li>
                </ul>


                <h3 className="mt-4 py-4">Psychology & Discipline of a Trader</h3>
                <ul>
                    <li>• Roadmap to plan your quantities as a beginner </li>
                    <li>• One on One Support Guidelines</li>
                </ul>
 
        </div>
        <div className="max-w-4xl mx-auto mt-6">
            <hr />
            <div className="mx-auto my-4 pt-6">
            Once you learn chart reading, you can start to understand different charts like Nifty50, Nifty Bank, Equity stocks and basically anywhere where there is a chart and candle.
            <div className="my-4"></div>
            Always backtest first and then live test with the smallest capital possible to gain confidence and verify your own skills.
            </div>
            <div className="text-center">
                 {/* <a className="bg-theme-blue p-2 rounded-lg" href={"/live-class-details"+(mobile?'-app':'')}>&lt; Back</a> */}
                 
            </div>
        </div>
       
    </div>)
}