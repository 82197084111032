import React, { useEffect, useState } from "react"
import ClipboardJS from "clipboard"
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import 'lite-youtube-embed'
import 'lite-youtube-embed/src/lite-yt-embed.css'
import './live-class.css'
import CourseDetails from "./CourseDetails"
import WEEKLYIMG from './../assets/weeklyperformance.jpeg'
import Modal from "./Modal"
import getNewDate from "../utils/getNewDate"
import FAQ from "./FAQ"

// const syllabus = [
//     ['Nature of Marke', 'Setups & Rules', 'Psychology & Risk Management'],
//     ['Price Behaviour', 'Support & Resistance', '40-60 Rules'],
//     ['Chart Reading', 'Chart Patterns', 'Level Profit Booking'],
//     ['Position Planning', 'When to Avoid<br/>When to Enter', 'Strike Price for best return'],
//     ['Common Sense<br/>&<br/>Logical Approach', 'Trailing Stoploss', 'Stoploss'],
//     ['-', '-', 'Psychological aspects of trading']
// ]
// const lectures = [
//     `Drawing Support & Resistance Levels`,
//     `Indicators & the reason for not using them`,
//     `Nature of Market & Price Travel`,
//     `Gap up Gap Down Strategy `,
//     `Fibonacci Setup `,
//     `M & W | 3 Line Setup`,
//     `Cup & Handle`,
//     `Head & Shoulder`,
//     `All Important Chart Patterns`,
//     `Strike Price Selection`,
//     `Putting the Stoploss`,
//     `Trailing Stoploss`,
//     `Trading in Trending Market`,
//     `Trading in Sideways Market`,
//     `Risk Management `,
//     `Psychology & Discipline of a Trader`,
// ]

export default () => {
    // const UPIID = ['prateekvarshney1224@oksbi','prateekvarshney1224@okicici','prateekvarshney1224@okhdfcbank','prateekvarshney1224@okaxis']
    const [dates, setDates] = useState([new Date(), new Date()])
    const UPIID =  ['varshneyenterprise@icici']

    const [showSyllabus, setShowSyllabus] = useState(false)
    const [showOneImage, setShowOneImage] = useState(false)
    const [showTiming, setShowTiming] = useState(false)
    
    var clipboard = null
    function clip(){
        if(clipboard){
           return  
        }
        clipboard = new ClipboardJS('.btn');

        clipboard.on('success', function(e) {
            // console.info('Text:', e.text);
            // console.info('Action:', e.action);
            // console.info('Trigger:', e.trigger);
            toast( e.text + " Copied!")
            e.clearSelection();
        });

    }

    //function getNewDate() {
    //    let today = new Date()
    //    //today = new Date(2023, 6, 4)
    //    //if(today.getMonth() <= 8 || (today.getMonth() <= 9 && today.getDate() <= 1)){
    //    //    // console.log('ss', Date(2023,8,1))
    //    //     return new Date(2023,9,2)
    //    // }
    //    if(today.getMonth() <= 9){
    //        // console.log('ss', Date(2023,8,1))
    //         return new Date(2023,10,1)
    //     }
    //    return today
    //}
    
    useEffect(() => {
        let today = getNewDate()// 
        let sunday = null, saturday = null
        
        console.log(today.getFullYear(), today.getMonth(), today, today.getDay())
        if(today.getDay()==6){
            console.log("Its saturday")
            // its saturday
            // console.log(today.getHours())

            if(today.getHours() >= 16){
                // show next date
                sunday = getNewDate()
                sunday.setDate(getNewDate().getDate()+8)
                saturday = getNewDate()
                saturday.setDate(getNewDate().getDate()+7)
            } else {
                // show this weeks date
                sunday = getNewDate()
                sunday.setDate(getNewDate().getDate()+1)
                saturday = getNewDate()
            }
            
        }
        else if(today.getDay()==0){
            // its sunday
            console.log("SUNDAY")
            sunday = getNewDate()
            sunday.setDate(getNewDate().getDate()+7)
            saturday = getNewDate()
            saturday.setDate(getNewDate().getDate()+6)
        } else {
            console.log("HHHH")
            sunday = getNewDate()
            sunday.setDate(getNewDate().getDate() + (7 - getNewDate().getDay()))

            saturday = getNewDate()
            saturday.setDate(getNewDate().getDate() + (6 - getNewDate().getDay()) )

        }
        setDates([saturday, sunday])
        // console.log(today, sunday, saturday)
    }, [])

    useEffect(() => {
        clip()
    })

    const nth = function(d) {
        if (d > 3 && d < 21) return 'th';
        switch (d % 10) {
          case 1:  return "st";
          case 2:  return "nd";
          case 3:  return "rd";
          default: return "th";
        }
      }

    function format(date){
        let dateStr = date.toDateString()
        const dateStrArr = dateStr.split(' ')
        
        return [date.getDate().toString()+nth(date.getDate()), dateStrArr[1]].join('-')
    }


    return (
        <>
         <div className="max-w-4xl mx-auto px-4 sm:px-6 py-6 text-center" style={{color: 'white', minHeight: 400}}>
            {/*<h2 className="text-center text-4xl my-4">Price Action - Chart Reading Course<br/>(1 month mentorship program)</h2>*/}
            <h4 className="py-4 mt-4"><b>"Currently no batches are being conducted, stay tuned for further updates."</b></h4>

            {/*<p className="mt-4">In the meanwhile check this <a href="https://youtube.com/playlist?list=PLJB-E05ZNmO1aCXD4jC_xQ4RirpEcJnf3&si=jvfTCBdqJ3aCxmKb" target="_blank"><u>playlist</u></a> for your learning.</p>*/}
        </div>
        </>
    )

    return (
    <div className="max-w-4xl mx-auto px-4 sm:px-6 py-6" style={{color: 'white', minHeight: 200}}>
        <h2 className="text-center text-4xl my-4">Price Action - Chart Reading Course<br/>(1 month mentorship program)</h2>
        <link rel="stylesheet" type="text/css" href={'mobileapp.css'} />
        <div>
        {/* 
            <div className="rounded-lg _bg-gray-900 p-4 mt-4">
                <h1 className="mb-2 text-2xl text-center">SYLLABUS</h1>
                <table>
                    <tbody>
                        {syllabus.map(row => (<tr>{row.map(col => { return (<td>{parse(col)}</td>)})}</tr>) )}
                    </tbody>
                </table>
            </div> 
        */}

            <div className="rounded-lg border border-gray-800 pt-4 pb-4 pl-2 pr-2">
                
                <lite-youtube videoid="onVh9dQ9YGU" width="400" height="200" style={{maxWidth: '100%', width: "400px", height: 200, margin: '0 auto'}} playlabel="Play: Demo Class"></lite-youtube>

                <h2 className="mt-2 mb-2 text-center">The Program that can help you understand & read the charts better.</h2>
                
                {/* 
                <ol className="my-4">
                    {lectures.map((v, bi) => {
                        return (<li className="bg-gray-800 mt-2 p-2" key={bi}>{v}</li>)
                    })}
                </ol> 
                */}

                <div className="rounded-lg _bg-gray-900 p-4 mt-4">
                {/* 
                Sirf 2 class se kuch nahi hoga
                <br />
                Roz homework karke har Saturday apna ek ek trade check krana hoga atleast 1 month tak.
                <br />

                Ek maheene ke aas paas poora lagega aapko
                <br />

                Jab tak Prateek Sir approval na de
                <br />
                Bina homework check karaye aap start nahi karoge trading. MEHNAT TO KARNI HI PADEGI! */}
                {/* <h3 className="bg-gray-800 p-4 text-bold">MUST LISTEN</h3> */}
                <div className="rounded-lg bg-gray-800 mt-4 p-4 mb-4">
                <b>Step 1 : Listen to this </b>
                </div>
                <audio controls className="mt-6">
                    <source src="/demoaudio.mp3" type="audio/mp3"></source>
                </audio>

                <div className="rounded-lg bg-gray-800 mt-4 p-4 mb-4">
                <b>Step 2 : Check the Syllabus </b><button className="bg-theme-blue2 px-2 -mt-1 float-right rounded-md" onClick={() => setShowSyllabus(!showSyllabus)} _href={"/course-details?a="+Date.now()}><span className="md:block hidden">{showSyllabus?'HIDE':'SHOW'}</span><span className="md:hidden block -mt-1"><i class={`indicator glyphicon glyphicon-chevron-${showSyllabus?'down':'up'}-custom pull-right`}><span class="sp-1"></span><span class="sp-2"></span></i></span></button>
                </div>

                {showSyllabus && <CourseDetails />}

                <div className="rounded-lg bg-gray-800 mt-4 p-4"><b>Two Interactive Live Classes on <br className="md:hidden inline"/>Google Meet (1.5 hours each)</b>
                    <button className="bg-theme-blue2 px-2 -mt-1 float-right rounded-md" onClick={() => setShowTiming(!showTiming)} _href={"/course-details?a="+Date.now()}><span className="md:block hidden">{showTiming?'HIDE':'SHOW'}</span><span className="md:hidden block -mt-1"><i class={`indicator glyphicon glyphicon-chevron-${showTiming?'down':'up'}-custom pull-right`}><span class="sp-1"></span><span class="sp-2"></span></i></span></button>
                </div>
                {showTiming && <div className="mt-4">
                    <p>
                        • {format(dates[0])} and {format(dates[1])}<br />
                        {/* • 4th and 5th March<br /> */}
                        • Time slots - 11:00 AM or 9:00 PM<sup>*</sup>  
                    </p>
                </div>}
                <div className="rounded-lg bg-gray-800 mt-4 p-4 mb-4 "><b>One on One Support every <br className="md:hidden inline"/>Saturday</b> <button className="bg-theme-blue2 px-2 -mt-1 rounded-md float-right" onClick={() => setShowOneImage(!showOneImage)} _href={"/course-details?a="+Date.now()}><span className="md:block hidden">{showOneImage?'HIDE':'SHOW'}</span><span className="md:hidden block -mt-1"><i class={`indicator glyphicon glyphicon-chevron-${showOneImage?'down':'up'}-custom pull-right`}><span class="sp-1"></span><span class="sp-2"></span></i></span></button></div>
                {showOneImage && <div>
                    <img src={WEEKLYIMG} style={{maxWidth: '100%', width: 500 }}/>
                    <br/>
                    You will submit like this every Saturday and clear each & every query of yours through voice notes, texts, images whatever will be needed as per your doubt.
                    </div>}
                    
                
                {/* <h2 className="my-6">DEMO CLASS</h2> */}

                {/* <iframe width="560" height="315" style={{maxWidth: '100%'}} src="https://www.youtube.com/embed/onVh9dQ9YGU" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe> */}

                {/* <h2 className="mb-2 mt-4">SYLLABUS</h2>
                
                <ol className="my-4">
                    {lectures.map((v, bi) => {
                        return (<li className="bg-gray-800 mt-2 p-2" key={bi}>{v}</li>)
                    })}
                </ol> */}
            </div>
                
            </div>
        
        {/* 
            <div className="rounded-lg _bg-gray-900 p-4 mt-4">
                <h2 className="mb-2">DETAILS</h2>
                <p>
                This Mentorship program is more than enough to give you a headstart in your Trading Journey. In 1 month you will experience all kinds of market including trending, ranging, more setups, less setups and no trading days.
                </p>
            </div>

            <div className=" p-4 mt-4">
                <h2 className="mb-2">DATE & TIME</h2>
                <p>
                    • {format(dates[0])} and {format(dates[1])}<br />
                    • Time slots - 11:00 AM or 9:00 PM
                </p>
            </div> 
        */}

            
            {/* <div className="rounded-lg bg-gray-900 p-4 mt-4">
            <b>COURSE SYLLABUS</b><a className="bg-theme-blue px-2 py-1 rounded-lg float-right" href="/course-details">SHOW</a>
            </div> */}
            

            <div className="rounded-lg _bg-gray-900 py-4 mt-4">
                <h2 className="mb-2">PRICE</h2>
                <p>
                    • ₹ 5,999 /-  <br />
                    {/* • 11am or 9pm (You have to book one) */}
                </p>

                {/* <h2 className="my-4">How to Pay?</h2> */}
                <div>
                    {/* 
                    Pay ₹ 5,999 using any application like Googlepay, Paytm, Phonepay on the following UPI ID
                    <ol>
                        {UPIID.map((v, bi) => {
                            return (<li className="_bg-gray-800 mt-2 p-2" key={bi}><button className="bg-theme-blue2 float-right px-2 py-1 round btn rounded-md" data-clipboard-text={v} onClick={() => {}}>COPY</button>{v}</li>)
                        })}
                    </ol>
                    <p className="my-6 py-4 text-center">Use following QR Code</p>
                    <img src={QRCode} alt="QR Code" style={{width: 200, maxWidth: '100%', margin: '0 auto'}}/>
                    <br /> */}
                    <p className="mt-4 mb-4">
                        {/* <a href="/app/refund-policy-app"><u>Refund Policy</u></a> */}
                        <Modal></Modal>
                    </p>
                    After Payment, send the payment screenshot and these details 
                    <br /> <br />
                    11am or 9pm (Choose any one)*<br />
                    Full Name<br />
                    Mobile Number<br />
                    Email ID<br /><br />

                    on Telegram <code><a aria-label="Telegram" href="https://t.me/optiontradingonlyadmin">@optiontradingonlyadmin</a></code>
                    <br/>
                    <br/>
                    <i>
                        <b>If the Telegram link gives you an error, then mail the screenshot at <a href="mailto:optiontradingonly@gmail.com">optiontradingonly@gmail.com</a></b>
                    </i>
                    <p className="mt-2"><small>* Batch timings are subject to availability.</small></p>
                    <FAQ></FAQ>
                    <div style={{height: 100, display: 'block'}}></div>
                </div>
                   <ToastContainer />
            </div>
        </div>
    
    </div>)
    
}

function Toast({text}){
    return (
        <div id="toast-default" className="flex items-center p-4 w-full max-w-xs text-gray-500 bg-green rounded-lg shadow" role="alert">
        <div className="inline-flex flex-shrink-0 justify-center items-center w-8 h-8 text-blue-500 bg-blue-100 rounded-lg">
            <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M12.395 2.553a1 1 0 00-1.45-.385c-.345.23-.614.558-.822.88-.214.33-.403.713-.57 1.116-.334.804-.614 1.768-.84 2.734a31.365 31.365 0 00-.613 3.58 2.64 2.64 0 01-.945-1.067c-.328-.68-.398-1.534-.398-2.654A1 1 0 005.05 6.05 6.981 6.981 0 003 11a7 7 0 1011.95-4.95c-.592-.591-.98-.985-1.348-1.467-.363-.476-.724-1.063-1.207-2.03zM12.12 15.12A3 3 0 017 13s.879.5 2.5.5c0-1 .5-4 1.25-4.5.5 1 .786 1.293 1.371 1.879A2.99 2.99 0 0113 13a2.99 2.99 0 01-.879 2.121z" clipRule="evenodd"></path></svg>
            <span className="sr-only">Fire icon</span>
        </div>
        <div className="ml-3 text-sm font-normal">Set yourself free.</div>
        <button type="button" className="ml-auto -mx-1.5 -my-1.5 bg-green text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex h-8 w-8" data-dismiss-target="#toast-default" aria-label="Close">
            <span className="sr-only">Close</span>
            <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
        </button>
    </div>
    )
}